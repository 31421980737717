/* eslint-disable  */
import axios from 'axios'

export function getPuntiVendita() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Log-PuntoVendita',
          metodo: 'getPuntiVendita',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getPuntoVendita(id) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, id,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Log-PuntoVendita',
          metodo: 'getPuntoVendita',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setPuntoVendita(puntoVendita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, puntoVendita,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Log-PuntoVendita',
          metodo: 'setPuntoVendita',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function deletePuntoVendita(puntiVendita) {
    // console.log('dentro reparti', puntiVendita)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, puntiVendita,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-PuntoVendita',
            metodo: 'deletePuntoVendita',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
export function getPuntiVenditaStatistiche(statistiche) {
    // console.log('dentro reparti', puntiVendita)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, statistiche,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-PuntoVendita',
            metodo: 'getPuntiVenditaStatistiche',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
export function getOrganizzazioniStatistiche(statistiche) {
    // console.log('dentro reparti', puntiVendita)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, statistiche,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Log-PuntoVendita',
            metodo: 'getOrganizzazioneStatistiche',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }

