var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-shops-event-handler",attrs:{"id":"modal-shops-event-handler","ok-only":"","ok-title":"Crea","centered":"","size":"md","no-close-on-backdrop":"","title":_vm.titleEventHandler},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)},"close":_vm.resetShop},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"validation-observer-shops-modal-event-handler"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Nome"},model:{value:(_vm.shop.nome),callback:function ($$v) {_vm.$set(_vm.shop, "nome", $$v)},expression:"shop.nome"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Codice"},model:{value:(_vm.shop.codice),callback:function ($$v) {_vm.$set(_vm.shop, "codice", $$v)},expression:"shop.codice"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"responsabile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Responsabile","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"responsabile"},model:{value:(_vm.shop.responsabile),callback:function ($$v) {_vm.$set(_vm.shop, "responsabile", $$v)},expression:"shop.responsabile"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"email"},model:{value:(_vm.shop.email),callback:function ($$v) {_vm.$set(_vm.shop, "email", $$v)},expression:"shop.email"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"telefono"},model:{value:(_vm.shop.telefono),callback:function ($$v) {_vm.$set(_vm.shop, "telefono", $$v)},expression:"shop.telefono"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"indirizzo"},model:{value:(_vm.shop.indirizzo),callback:function ($$v) {_vm.$set(_vm.shop, "indirizzo", $$v)},expression:"shop.indirizzo"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Magazzini Associati',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Magazzini Associati","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mr-md-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"reduce":function (val) { return ({ id: val.id}); },"filterable":"","multiple":"","options":_vm.magazziniOption,"placeholder":"magazzini"},on:{"open":_vm.getMagazziniOption,"input":_vm.limiterSelection},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca Magazzini...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento Magazzini...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.shop.magazzini),callback:function ($$v) {_vm.$set(_vm.shop, "magazzini", $$v)},expression:"shop.magazzini"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Listini Associati',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Listini Associato","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"multiple":"","reduce":function (val) { return ({ id: val.id}); },"filterable":"","placeholder":"listini","options":_vm.listiniOption},on:{"open":_vm.getListiniOption,"input":_vm.limiterSelection},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca listini...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento listini...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.shop.listini),callback:function ($$v) {_vm.$set(_vm.shop, "listini", $$v)},expression:"shop.listini"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrizione","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Descrizione"},model:{value:(_vm.shop.descrizione),callback:function ($$v) {_vm.$set(_vm.shop, "descrizione", $$v)},expression:"shop.descrizione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }