<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-modal
    id="modal-shops-event-handler"
    ref="modal-shops-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    no-close-on-backdrop
    :title="titleEventHandler"
    @ok.prevent="handleOk"
    @close="resetShop"
  >
    <!-- {{ shop }} -->
    <validation-observer ref="validation-observer-shops-modal-event-handler">
      <b-row no-gutters>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.nome"
                  placeholder="Nome"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.codice"
                  placeholder="Codice"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="responsabile"
            rules="required"
          >
            <b-form-group
              label="Responsabile"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.responsabile"
                  placeholder="responsabile"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.email"
                  placeholder="email"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="telefono"
            rules="required"
          >
            <b-form-group
              label="Telefono"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.telefono"
                  placeholder="telefono"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="indirizzo"
            rules="required"
          >
            <b-form-group
              label="Indirizzo"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.indirizzo"
                  placeholder="indirizzo"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="'Magazzini Associati'"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Magazzini Associati"
              rules=""
            >
              <v-select
                v-model="shop.magazzini"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                :reduce="(val) => ({ id: val.id})"
                filterable
                multiple
                :options="magazziniOption"
                class="mr-md-1"
                placeholder="magazzini"
                @open="getMagazziniOption"
                @input="limiterSelection"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca Magazzini...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento Magazzini...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="'Listini Associati'"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Listini Associato"
              rules=""
            >
              <v-select
                v-model="shop.listini"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                multiple
                :reduce="(val) => ({ id: val.id})"
                filterable
                placeholder="listini"
                :options="listiniOption"
                @open="getListiniOption"
                @input="limiterSelection"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca listini...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento listini...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="shop.descrizione"
                  placeholder="Descrizione"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { setPuntoVendita } from '@/@core/api-service/logistic/puntiVendita'
import { getMagazzini } from '@/@core/api-service/logistic/warehouses'
import { getListini } from '@/@core/api-service/catalog/priceList'
import axios from 'axios'
Vue.use(ToastPlugin)

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, vSelect,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      shop: {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        responsabile: '',
        email: '',
        telefono: '',
        indirizzo: '',
        magazzini: [],
        listini: [],
      },
      required,
      locale: 'it',
      repartiOption: [],
      magazziniOption: [],
      listiniOption: [],
    }
  },
  mounted() {
    localize(this.locale)
  },
  computed: {
      repartiPadreOption() {
          // this.$store.getters['logistic/getRecapiti']
          return this.$store.getters['logistic/getShops']
          // return this.repartiOption
      },
  },
  methods: {
    limiterSelection(e) {
      if(e.length > 1) {
        e.pop()
      }
    },
    handleOk() {
      this.setShops()
    },
    setShop(shop) {
      this.shop = { ...shop }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    getMagazziniOption() {
      getMagazzini().then(res => {
        if(res.data.esito === 'OK') {
          this.magazziniOption = [...res.data.magazzini]
        }
      }).catch(e => e)
    },
    getListiniOption() {
      getListini().then(res => {
        console.log(res)
        if(res.data.esito === 'OK') {
          this.listiniOption = [...res.data.listini]
        }
      }).catch(e => e)
    },
    async setShops() {
      if (!Object.prototype.hasOwnProperty.call(this.shop, 'magazzini') ) {
        this.shop.magazzini = []
      }
      if (!Object.prototype.hasOwnProperty.call(this.shop, 'listini')) {
        this.shop.listini = []
      }
      const escludiMagazzini = 'N'
      const escludiListini = 'N'
      // console.log(this.shop)
      // console.log('escludiListini', escludiListini)
      // console.log('escludiMagazzini', escludiMagazzini)
      const puntoVendita = JSON.stringify([{...this.shop, escludiMagazzini: escludiMagazzini, escludiListini: escludiListini }])
      // console.log('puntoVendita', puntoVendita)
      setPuntoVendita(puntoVendita).then(res => {
        // console.log('setPuntoVendita', res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          const shops = [...res.data.puntivendita.map(el => ({
            id: el.id,
            nome: el.nome,
            descrizione: el.descrizione,
            codice: el.codice,
            responsabile: el.responsabile,
            email: el.email,
            telefono: el.telefono,
            indirizzo: el.indirizzo,
            magazzini: [...el.magazzini],
            listini: [...el.listini],
          }))]
          console.log('shops', shops)
          this.$emit('update-shops', shops)
          this.resetShop()
          this.$nextTick(() => {
            this.$refs['modal-shops-event-handler'].toggle('#toggle-btn')
          })
        } else if (res.data.esito === 'KO') {
          this.$refs.toastRef.show({
            title: 'Errore Aggiunta', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    resetShop() {
      this.shop = {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        responsabile: '',
        email: '',
        telefono: '',
        indirizzo: '',
        magazzini: [],
        listini: [],
      }
    },
  },
}
</script>

<style>

</style>
